import React,{ Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

class Pagenotfound extends Component{
    constructor(props){
        super(props)
        this.state = { }
    }
    render(){
        return(
            <div>
            <Navbar />
            <section className="py-5 bg-dark position-relative">
                <div id="particles-js"></div>
                <div className="container py-5 mt-5 z-index-20">
                    <h1 className="text-center">404 Page not found</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center">
                            <li className="breadcrumb-item">
                                <a className="text-decoration-none d-flex align-items-center" href="#"> <i className="las la-home la-sm me-1"></i>Home</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">404 Page not found</li>
                        </ol>
                    </nav>
                </div>
            </section>
            <Footer />
        </div>
        )
    }
}
export default Pagenotfound;