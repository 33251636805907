import React,{ useState, useEffect } from 'react'

function MultiplePay(props) {
  const [menus, setMenus] = useState([])

  const menuClick = (e) =>{
    var addTrue = 0;
    if(menus.length>0){
      var indexF = menus.indexOf(e);
      if(indexF >-1){
        console.log(menus);
        console.log("indexF = "+indexF)
        console.log('chk..')
        menus.splice(indexF, 1);
        console.log(menus);
        console.log('set menus..1')
        setMenus([]);
        setTimeout(() => {
          console.log('set menus..2')
          setMenus(menus);
          console.log(menus);
        }, 100);
       
        return
      }
    }

    if(menus.length<2){
      console.log(menus);
      console.log('set menus..3')
      setMenus([...menus, e]);
    }
   
  }
  useEffect(() => { 
    console.log('menus effect');

    props.payDatas(menus)

  }, [menus])

  return (
    <div>
      {/* json {JSON.stringify(menus) } */}
        <ul className="sid-menu">
                <li><a className={(menus.indexOf("card") !=-1)?'active':''} 
                onClick={()=>{menuClick('card')}}
                >Push2Card</a>
                </li>
                <li><a className={(menus.indexOf("wire") !=-1)?'active':''}
                onClick={()=>{menuClick('wire')}}
                >Wire</a> </li>
                <li><a className={(menus.indexOf("ach") !=-1)?'active':''}
                onClick={()=>{menuClick('ach')}}
                >ACH</a> </li>
                <li><a className={(menus.indexOf("rtp") !=-1)?'active':''}
                onClick={()=>{menuClick('rtp')}}
                >RTP</a> </li>
                <li><a className={(menus.indexOf("voucher") !=-1)?'active':''}
                onClick={()=>{menuClick('voucher')}}
                >Voucher</a> </li>
                <li><a  className={(menus.indexOf("precard") !=-1)?'active':''}
                onClick={()=>{menuClick('precard')}}
                >Prepaid Card</a> </li>
                <li><a className={(menus.indexOf("paypal") !=-1)?'active':''}
                onClick={()=>{menuClick('paypal')}}
                >Paypal </a> </li>
            </ul>
    </div>
  )
}

export default MultiplePay