import React, { useState, useEffect } from 'react'
//import { FaCcMastercard, FaCcVisa, FaCreditCard } from 'react-icons/fa';
import cardImages from '../cards.jpg';
import masterCard from '../master.png';
import visaCard from '../visa.png';
import amex from '../amex.png';

function Card(props) {
    const [cardData, setCardData] = useState({
        card_type: '',
        card_number: ''
    })

    useEffect(() => {
        props.datas(cardData);
    }, [cardData])

    const handalInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (value != '') {
            setCardData({ ...cardData, [name]: value })
        }
    }
    return (
        <div>
            <div className=''>
                <div class="form-outline form-white mb-4">
                    <label> <img src={masterCard} style={{ height: '30px' }} />
                        <input type='radio' name='card_type' value="master"

                            onChange={handalInput} />
                    </label>
                </div>
                <div class="form-outline form-white mb-4">
                    <label> <img src={visaCard} style={{ height: '30px' }} />
                        <input type='radio' name='card_type' value="visa" onChange={handalInput} />
                    </label>
                </div>
                <div class="form-outline form-white mb-4">
                    <label> <img src={amex} style={{ height: '30px' }} />
                        <input type='radio' name='card_type' value="amex" onChange={handalInput} />
                    </label>
                </div>
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Card Number</label>
                <input type="text" class="form-control form-control-lg" 
                    name="card_number"
                    value={cardData.card_number}
                    onChange={(e) => { handalInput(e) }}
                />
            </div>
        </div>
    )
}

export default Card