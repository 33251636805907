import React, { Component } from "react";

import withNavigateHook from "./withNavigateHook";

import Navbar from "./Navbar";
import Footer from "./Footer";
import ReCAPTCHA from "react-google-recaptcha";

class LoginKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            key_data: '',
            pasword: '',
            confirm_pasword: '',
        }
        this.login = this.login.bind(this)
    }

    login = async (e) => {

        if (this.state.email == '' || this.state.key_data == '') {
            alert('All fields are mandatory');
            return;
        }

        let res = await fetch("https://redeembank.com/api/claim.php", {
            method: 'POST',
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.pasword,
                key_data: this.state.key_data,
            })
        })
            .then((response) => response.json())
            .then((responseData) => {
                this.setState({ email: '' });
                this.setState({ email: '' });
                if (!responseData.error) {
                   // localStorage.setItem('user', JSON.stringify(responseData.result[0]))

                    this.props.navigation(`/payment-success/${responseData.result[0].key_data}`);
                    
                }
            })
    }
    componentDidMount(){
        console.log(this.props);
    }

    render() {
        return (
            <div>
                <Navbar />
                <section className="py-5 bg-dark position-relative">
                    <div id="particles-js"></div>
                    <div className="container py-5 mt-5 z-index-20">
                        <h1 className="text-center">Login</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item">
                                    <a className="text-decoration-none d-flex align-items-center" href="#"> <i className="las la-home la-sm me-1"></i>Home</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Login</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="py-5 position-relative">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-8 mx-auto">

                                <div className="card">
                                    <div className="card-body p-4 p-lg-5">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src="https://www.redcross.org/content/dam/redcross/imported-images/redcross-logo.png.img.png" />
                                    </div>

                                        <form className="login-form" action="#">
                                            <div className="row gy-3">
                                                <div className="col-lg-12">
                                                    <label className="form-label" for="email"> Email address</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-envelope"></i></div>
                                                        <input className="form-control shadow-0 border-start-0" type="email"
                                                            value={this.state.email}
                                                            onChange={(e) => { this.setState({ email: e.target.value }) }}
                                                            placeholder="Enter your email address" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className="form-label" for="password"> Key</label>
                                                    <div className="input-group">
                                                        <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-key"></i></div>
                                                        <input className="form-control shadow-0 border-start-0" 
                                                        type="text"
                                                            value={this.state.key_data}
                                                            onChange={(e) => { this.setState({ key_data: e.target.value }) }}
                                                            placeholder="Enter your key" />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6 mb-3">
                                                    <ReCAPTCHA
                                                        sitekey="6Le9vwUeAAAAAJwSJl9K0XgRsMeJT1AmksODmex"
                                                        onChange={this.onChange}
                                                    />
                                                </div> */}
                                                <div className="col-lg-12 mb-3">
                                                    <button className="btn btn-primary w-100" type="button"
                                                        onClick={this.login}
                                                    ><i className="las la-sign-in-alt me-2"></i>Go</button>
                                                </div>
                                                <div className="col-12 text-center">
                                                    <p className="mb-0 fw-light text-muted">Not registered yet! <a className="text-decoration-none" href="register"> Create an account</a></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}
//export default Login;
export default withNavigateHook(LoginKey)