import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Country from "./Country";

class AddVoucher extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            name: '',
            details: '',
            imgUrl: '',
            amount: '',
            valid_date:'',
            userId: -1
        }
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handalSubmit = this.handalSubmit.bind(this);
    }

    handalSubmit = async (e) => {
        if(this.state.name =="" || this.state.details =="" || this.state.amount =="" || this.state.valid_date ==""){
            alert('All field are mandatory !!')
            return
        }
        let res = await fetch("https://redeembank.com/api/add-voucher.php", {
            method: "POST",
            body: JSON.stringify({
                name: this.state.name,
                details: this.state.details,
                amount: this.state.amount,
                valid_date: this.state.valid_date,
                imgUrl: this.state.imgUrl,
                userId: this.state.userId
            }),
        });
        if (res.status == 200) {
            this.setState({ name: '', details: '', imgUrl: '', amount: '', valid_date:'' })
        }
    }
    handleFileUpload(event) {
        alert('upload')
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("imagefile", file);
        axios
            .post("https://redeembank.com/api/upload.php", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            })
            .then((response) => {
                // handle the response imgUrl
                if (response.status == 200) {
                    this.setState({ imgUrl: response.data.url });
                }
            })
            .catch((error) => {
                // handle errors
                console.log(error);
            });
    }
    componentDidMount() {
        let userData = JSON.parse(localStorage.getItem("user"))
        this.setState({ userId: userData.id })
        
    }
    render() {
        return (
            <div >
                <div class="wrapper">
                    <Sidebar />
                    <div id="content">
                        <Header />
                        <p>Voucher upload </p>
                        <div className="row">
                            <div className="col-sm-10">
                                <div class="card" >
                                    <div className="card-title p-3 mb-2 bg-secondary text-white">
                                        <h5 className="text-center">Voucher upload</h5>
                                    </div>
                                    <div class="card-body">
                                        <form >
                                            <div class="form-group">
                                                <label>Name:</label>
                                                <input type="text" class="form-control"
                                                    value={this.state.name}
                                                    onChange={(e) => { this.setState({ name: e.target.value }) }}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Description:</label>
                                                <textarea className="form-control"
                                                    value={this.state.details}
                                                    onChange={(e) => { this.setState({ details: e.target.value }) }}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Amount ($):</label>
                                                <input type="text" class="form-control"
                                                    value={this.state.amount}
                                                    onChange={(e) => { this.setState({ amount: e.target.value }) }}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Validity date :</label>
                                                <input type="date" class="form-control"
                                                value={this.state.valid_date}
                                                onChange={(e)=>{this.setState({valid_date: e.target.value})}}
                                                 />
                                            </div>

                                            <div class="form-group">
                                                <label>Redemption Location:</label>
                                                <select className="form-control" >
                                                    <option>select country</option>
                                                    {
                                                        <Country />
                                                    }
                                                </select>
                                            </div>
                                            
                                            <div class="form-group">
                                            <label>Image:</label>
                                            <br />
                                                <label htmlFor="filePicker" 
                                                style={{ background:"grey", padding:"10px 10px" }}>
                                                choose file 
                                                </label>
                                                <input id="filePicker" style={{visibility:"hidden"}} type={"file"}
                                                onChange={this.handleFileUpload} />
                                            </div>

                                            {this.state.edit == false &&
                                                <div class="d-grid gap-2 d-md-block p-3 text-right" style={{textAlign:'right'}}>
                                                    <button class="btn btn-primary btn-block pull-right" type="button"
                                                        onClick={this.handalSubmit}
                                                    >Add</button>
                                                </div>}
                                            {this.state.edit == true &&
                                                <div class="d-grid gap-2 d-md-block p-3 ">
                                                    <button class="btn btn-primary btn-block" type="button"
                                                        onClick={this.handalUpdate}
                                                    >Update</button>
                                                </div>}

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddVoucher;