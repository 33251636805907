import React,{ Component } from "react";
import withNavigateHook from "../withNavigateHook";
//import { Sidebar, Menu, MenuItem, useProSidebar   } from "react-pro-sidebar";
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent
  } from "react-pro-sidebar";
  import {
    FiHome,
    FiLogOut,
    FiArrowLeftCircle,
    FiArrowRightCircle
  } from "react-icons/fi";

  import { FaGift, FaGifts, FaList, FaRegHeart } from "react-icons/fa";
  import { RiPencilLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";

//import sidebar css from react-pro-sidebar module and our custom css
//import "react-pro-sidebar/dist/css/styles.css";

import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from 'react-router-dom';

class SideBar extends Component{
    constructor(props){
        super(props)
        this.state = {
            menuCollapse: false,
            userData: JSON.parse(localStorage.getItem('user')) ,
        }
        var userData = localStorage.getItem('user');
        console.log(userData)
        if (userData === null){
            this.props.navigation('/login');
        }
        this.logout = this.logout.bind(this)
        this.menuIconClick = this.menuIconClick.bind(this)
        this.master = this.master.bind(this)
    }
    logout(){
        console.log('logout')
        localStorage.clear();
        window.location.href = '/';
        //this.props.navigation('/login');
        return
    }
    menuIconClick(){
        this.setState({menuCollapse: !this.state.menuCollapse});
    }
    master(){
        console.log("click")
    }

    render(){
        return(

            <ProSidebar collapsed={this.state.menuCollapse}>
                <SidebarHeader>
                    <div className="logotext">
                        {/* small and big change using menucollapse state */}
                        <p></p>
                        </div>
                        <div className="closemenu" onClick={this.menuIconClick}>
                        {/* changing menu collapse icon on click */}
                        {this.state.menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    {this.state.userData.level=='0' &&
            <Menu iconShape="square">
              <MenuItem active={true} icon={<FiHome />} component={<Link to="/dashboard" />}>Dashboard</MenuItem>
              <MenuItem icon={<FaList />} ><Link to="/category">Category Master</Link> </MenuItem>
              
              <MenuItem icon={<FaList />} ><Link to="/add-product">Product upload</Link></MenuItem>
              <MenuItem icon={<FaList />} ><Link to="/product-list">Product list</Link></MenuItem>

              <MenuItem icon={<FaGift />} ><Link to="/add-voucher">Voucher upload</Link></MenuItem>
              <MenuItem icon={<FaGifts />} ><Link to="/voucher-list">Voucher list</Link></MenuItem>

              <MenuItem icon={<FaList />} ><Link to="/user-list" >All Users </Link> </MenuItem>
              <MenuItem icon={<FaList />} ><Link to="/business-list" >All Business </Link> </MenuItem>
              <MenuItem icon={<BiCog />}><Link onClick={this.logout} >Logout</Link></MenuItem>
            </Menu>}
            {this.state.userData.level=='3' &&
            <Menu iconShape="square">
              <MenuItem active={true} icon={<FiHome />} component={<Link to="/dashboard" />}>Dashboard</MenuItem>              
              <MenuItem icon={<FaList />} ><Link to="/add-product">Product upload</Link></MenuItem>
              <MenuItem icon={<FaList />} ><Link to="/product-list">Product list</Link></MenuItem>

              <MenuItem icon={<FaGift />} ><Link to="/add-voucher">Voucher upload</Link></MenuItem>
              <MenuItem icon={<FaGifts />} ><Link to="/voucher-list">Voucher list</Link></MenuItem>
              <MenuItem icon={<FaGifts />} ><Link to="/selling-list">Selling product list</Link></MenuItem>

              <MenuItem icon={<BiCog />}><Link onClick={this.logout} >Logout</Link></MenuItem>
            </Menu>}
            {this.state.userData.level=='1' &&
            <Menu iconShape="square">
              <MenuItem active={true} icon={<FiHome />} component={<Link to="/dashboard" />}>Dashboard</MenuItem>
              <MenuItem icon={<FaList />} ><Link to="/add-product">Product upload</Link></MenuItem>
              <MenuItem icon={<FaList />} ><Link to="/product-list">Product list</Link></MenuItem>
              <MenuItem icon={<FaList />} ><Link to="/selling-list">Buy Product list</Link></MenuItem>
              <MenuItem icon={<BiCog />}><Link onClick={this.logout} >Logout</Link></MenuItem>
            </Menu>}
          </SidebarContent>
            </ProSidebar>
            
            
        );
    }
}
export default withNavigateHook(SideBar) ;