import React, { Component } from "react";

import withNavigateHook from "./withNavigateHook";

import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

class GalaryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: [],
            user: ""
        }
    }


    componentDidMount() {
        var logData = JSON.parse(localStorage.getItem('user'))
        if (logData == null) {

        } else {
            this.setState({ user: logData })
        }
        console.log(this.props.location.state.keyval);
        if (this.props.location.state.keyval && this.props.location.state.keyval != '') {
            const keyval = this.props.location.state.keyval;
            fetch("https://redeembank.com/api/galary-list.php?key=" + keyval)
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ productList: json.result })
                })
        }

    }

    render() {
        return (
            <div>
                <Navbar />
                <section className="bg-section" style={{ paddingTop: '150px' }}>
                    <div className="container">
                        <h2>List </h2>
                        {this.state.productList.map((list) => (
                            <div className="col-xl-3 col-lg-4 col-md-6 mix art" key={list.id}>
                                <div className="card rounded shadow-sm card-hover-image">
                                    <div className="card-body p-3 position-relative">
                                        <div className="position-relative mb-4 shadow">
                                            
                                            <div className="card-img-holder rounded overflow-hidden">
                                                <img className="img-fluid rounded w-100"
                                                    src={list.img_url} alt="" />

                                            </div>
                                            <p>Author: <span className="text-primary ms-2"> {list.fullname} </span></p>
                                        </div>
                                        {this.state.user == "" &&
                                            <p className="fw-bold mb-3">
                                                <Link className="text-reset stretched-link"
                                                    to={`/login?product_id=${list.id}`}>
                                                    {list.purposr.substring(0, 20)} ...
                                                </Link>
                                            </p>}
                                        {this.state.user != "" &&
                                            <p className="fw-bold mb-3">
                                                <Link className="text-reset stretched-link" to={`/product-details/${list.id}`}>
                                                    {list.purposr.substring(0, 20)} ...
                                                </Link>
                                            </p>}

                                        <p className="text-sm text-muted fw-normal d-flex align-items-center">
                                            <span className="icon bg-primary text-white me-2"><i className="las la-wallet fa-fw"></i></span><span>Highest bid:
                                            </span><span className="text-primary ms-2">{list.amount}  ETH</span>
                                        </p>
                                        <div className="my-3 pt-1 bg-body rounded-pill"></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}
//export default Login;
export default withNavigateHook(GalaryList)