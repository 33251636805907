import React from 'react'

function Crypto() {
    return (
        <div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Location</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="location"
                    onChange={this.ChangeInput}
                    value={this.state.paymentData.location} />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Bank details</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="bank_details"
                    onChange={this.ChangeInput}
                    value={this.state.paymentData.bank_details} />
            </div>
        </div>
    )
}

export default Crypto