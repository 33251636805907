import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Header from "./Header";
import ToggleButton from 'react-toggle-button'


class VoucherList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productList: []
        }
        this.handelToggle = this.handelToggle.bind(this);
        this.handalClickEdit = this.handalClickEdit.bind(this)
        this.deleteItm = this.deleteItm.bind(this)
    }
    handelToggle(prod, v) {
        prod.enabled = !v;
        this.state.productList.find((usr) => {
            if (usr.id == prod.id) {
                usr.enabled = !v;
                return;
            }
        })
        this.setState({ productList: this.state.productList })
        let update = fetch('https://redeembank.com/api/product-update.php', {
            method: 'POST',
            body: JSON.stringify({
                prod_id: prod.id,
                enabled: prod.enabled
            })
        })
    }
    deleteItm = (id)=>{
         fetch('https://redeembank.com/api/voucher-delete.php',
         {
            method: 'POST',
            body: JSON.stringify({
                id: id
            })
        }
         )
         fetch("https://redeembank.com/api/voucher-list.php")
         .then((res) => res.json())
         .then((json) => {
             this.setState({ productList: json.result })
         })
    }
    handalClickEdit = (data) => {
        console.log(data);
        this.props.navigation('/add-product', { state: { id: 7, color: 'green' } })
    }
    componentDidMount() {
        console.log(this.props)
        fetch("https://redeembank.com/api/voucher-list.php")
            .then((res) => res.json())
            .then((json) => {
                this.setState({ productList: json.result })
            })
    }
    render() {
        return (
            <div >
                <div class="wrapper">
                    <Sidebar />
                    <div id="content">
                        <Header />
                        <p>Voucher List</p>
                        <div className="row">
                            <div className="col-sm-10">
                                <div class="card" >
                                    <div className="card-title p-3 mb-2 bg-secondary text-white">
                                        <h5 className="text-center">Voucher list</h5>
                                    </div>
                                    <div class="card-body">
                                        <table class="table table-dark table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Amount</th>
                                                    <th>Action</th>
                                                    <th>Enabled</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.productList.length > 0 &&
                                                    <>
                                                        {this.state.productList.map((list,i) => (
                                                            <tr key={list.id}>
                                                                <td>{(i+1)} </td>
                                                                <td>{list.name} </td>
                                                                <td>{list.details} </td>
                                                                <td>{list.sell_value} </td>
                                                                <td>

                                                                    <button className="btn btn-danger btn-sm"
                                                                        onClick={() => this.deleteItm(list.id)}>Del</button>
                                                                    <button type="button"
                                                                        onClick={() => { this.handalClickEdit(list) }}
                                                                        className="btn btn-warning btn-sm">Edit</button>

                                                                </td>
                                                                <td>
                                                                    <ToggleButton
                                                                        value={list.enabled}
                                                                        onToggle={(value) => this.handelToggle(list, value)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default VoucherList;