import React, { Component } from "react";
import './Home.css'
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FaFilter, FaGift, FaLongArrowAltRight } from 'react-icons/fa';
import withNavigateHook from "./withNavigateHook";

class ConfirmPayment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order_data:''
        }
        this.goConfirmPament = this.goConfirmPament.bind(this)
    }
    goConfirmPament() {
        console.log(this.props)
        this.props.navigation('/payment-confirm')
    }
    componentDidMount() {
        const transaction_id = this.props.params.transaction_id;
        //C:\inetpub\wwwroot\api\redeem-bank\order_data.php
        fetch('https://redeembank.com/api/order_data.php?transaction_id='+transaction_id)
            .then((response) => response.json())
            .then((resData)=>{
                console.log(resData);
                this.setState({order_data:  resData.result[0]})
            })
    }
    render() {

        return (
            <div>

                <Navbar></Navbar>
                <section className="py-5 position-relative main-div">
                    <div id="particles-js"></div>
                    <div className="container py-5 mt-5 z-index-20">
                        <h1 className="text-center text-color">Confirm Payment </h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item">
                                    <a className="text-decoration-none d-flex align-items-center text-color" href="#">
                                        <i className="las la-home la-sm me-1"></i>Home</a>
                                </li>
                                <li className="breadcrumb-item active text-color" aria-current="page">Gift voucher</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section>
                    {/* style="background-color: #eee;" */}
                    <section class="h-100 h-custom" >
                        <div class="container py-5 h-100">
                            <div class="row d-flex justify-content-center align-items-center h-100">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-body p-4">
                                            <div>
                                                <p>
                                                Thank you {this.state.order_data.fullname} for Submitting the claim form. We will get back to you soon.
                                                      </p>
                                                <p>Email: {this.state.order_data.email} </p>
                                                <p>Price:  $ {this.state.order_data.amount} </p>
                                                <p>Claim status: Success   </p>
                                                <p>Claim id: {this.props.params.transaction_id }   </p>
                                                <hr />
                                                Total: $ {this.state.order_data.amount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <Footer />

            </div>
        )
    }
}

export default withNavigateHook(ConfirmPayment)