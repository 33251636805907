import React, { Component } from "react";
import './Home.css'
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FaArrowAltCircleRight, FaFilter, FaGift, FaLongArrowAltRight } from 'react-icons/fa';
import withNavigateHook from "./withNavigateHook";
import SingleMenu from "./SingleMenu";
import MultiplePay from "./MultiplePay";
import Rtp from "./payments/Rtp";
import Payple from "./payments/Payple";
import Voucher from "./payments/Voucher";
import PreCard from "./payments/PreCard";
import Crypto from "./payments/Crypto";
import Wire from "./payments/Wire";
import Ach from "./payments/Ach";
import Card from "./payments/Card";
import Pickup from "./payments/Pickup";

class PaymentSuccess extends Component {
    constructor(props) {
        super(props)

        this.state = {
            price: 0,
            purpose: '',
            user_id: '',
            user_email: '',
            user_name: '',
            selectedPayment: 'card',
            paymentData: {
                card_num: '',
                bank_details: '',
                ac_num: '',
                routing_code: '',
                swift: '',
                rcv_ac_num: '',
                crd_ac_num: '',
                ac_type: '',
                idenf: '',
                sec_code: '',
                location: '',
                //card_type: '',
                name: '',
                //card_exp: '',
                bank_swift: '',
                bank_name: ''
            },
            clemData: '',
            transaction: '',
            savepass: false,
            password1: '',
            password2: '',
            single_payment: true,
            multiplePayments:[],
            multiplePayDatas:[],
            amounts:{}
        }

        this.goConfirmPament = this.goConfirmPament.bind(this)
        this.clcikMethod = this.clcikMethod.bind(this)
        this.ChangeInput = this.ChangeInput.bind(this);
        this.edit = this.edit.bind(this);
        this.clickPassword = this.clickPassword.bind(this);
        this.paymentMethod = this.paymentMethod.bind(this);
        this.getPMethods = this.getPMethods.bind(this);
        this.getCardData = this.getCardData.bind(this);
        this.getMulTipleData = this.getMulTipleData.bind(this);
        this.handalAmount = this.handalAmount.bind(this);

    }
    handalAmount(e){
        var amount = Number(this.state.clemData.amount);
        var inpAmt = Number(e.target.value);
        var thardPers = (amount*30)/100;

        const name = e.target.name;
        const val = e.target.value;
        this.setState(prevState => ({
            amounts: { ...prevState.amounts, [name]: val },
        }));

        if((thardPers > inpAmt) || (amount < inpAmt)){
          ///  alert('Please enter < '+thardPers);
          console.log(e.target.className);
          e.target.className = 'form-control error';
        }else{
            e.target.className = 'form-control';
           
        }
    }
    getMulTipleData(paymentData){

        this.setState(prevState => ({
            paymentData: { ...prevState.paymentData, ...paymentData },
          }));
    }
    getCardData(paymentData){
       this.setState({...this.state.paymentData,  paymentData})
       console.log(this.state.paymentData)
    }
    getPMethods(e){
        this.setState({multiplePayments:e})
    }
    paymentMethod(e) {
        this.setState({ selectedPayment: e })
    }
    clickPassword() {
        this.setState({ savepass: !this.state.savepass })
    }
    ChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            paymentData: {
                ...this.state.paymentData,
                [name]: value
            }
        })
    }
    clcikMethod(type) {
        this.setState({ selectedPayment: type });
        this.setState({ savepass: false });

    }
    edit() {
        let res = fetch("https://redeembank.com/api/save-order.php", {
            method: 'POST',
            body: JSON.stringify({
                price: this.state.clemData.amount,
                purpose: this.state.clemData.purposr,
                payment_data: JSON.stringify(this.state.paymentData),
                payment_type: this.state.selectedPayment,
                transaction: this.state.transaction,
                method: 'edit',
            })
        })
            .then((response) => response.json())
            .then((responseData) => {
                this.props.navigation('/payment-confirm/' + this.state.transaction)
            })
    }
    goConfirmPament() {
        console.log(this.props)
        console.log(this.state)
        if(this.state.single_payment ==false){
            var total =0;
            for (let i = 0; i < this.state.multiplePayments.length; i++) {
                var keyy = this.state.multiplePayments[i];
                if(this.state.amounts[keyy]){
                    console.log(this.state.amounts[keyy])
                    total += Number(this.state.amounts[keyy])
                }
            }
            if(total==Number(this.state.clemData.amount)){

            }else{
                alert('Please enter correct amount')
                return;
            }
            
        }
        let price = this.state.clemData.amount;
        if(this.state.selectedPayment =="voucher"){
            price = this.state.paymentData.amount;
        }
        if(this.state.selectedPayment =="wire"){
            if(!this.state.paymentData.bank_swift || this.state.paymentData.bank_swift==''){
                alert('Please enter SWIFT')
                return;
            }
            if(!this.state.paymentData.ac_num || this.state.paymentData.ac_num==''){
                alert('Please enter AC. number')
                return;
            }
            if(!this.state.paymentData.bank_name || this.state.paymentData.bank_name==''){
                alert('Please enter bank name')
                return;
            }
        }
        if(this.state.selectedPayment =="ach"){
            if(!this.state.paymentData.ac_num || this.state.paymentData.ac_num==''){
                alert('Please enter AC number')
                return;
            }
            if(!this.state.paymentData.routing_num || this.state.paymentData.routing_num==''){
                alert('Please enter routing number')
                return;
            }
            if(!this.state.paymentData.ac_type || this.state.paymentData.ac_type==''){
                alert('Please enter AC type')
                return;
            }
        }
        console.log(price)
    //   return;
        const randNum = Math.round(11111111 + (Math.random() * (99999999 - 11111111)));
        let res = fetch("https://redeembank.com/api/save-order.php", {
            method: 'POST',
            body: JSON.stringify({
                price: price ,//this.state.clemData.amount,
                purpose: this.state.clemData.purposr,
                key_data: this.props.params.keyid,
                method: 'add',
                user_id: this.state.user_id,
                payment_data: JSON.stringify(this.state.paymentData),
                amounts: JSON.stringify(this.state.amounts),
                multiplePayments: JSON.stringify(this.state.multiplePayments),
                single_payment: this.state.single_payment==true?1:0,
                payment_type: this.state.selectedPayment,
                transaction: randNum,
                password: this.state.password1,
                email: this.state.user_email,
            })
        })
            .then((response) => response.json())
            .then((responseData) => {
                this.props.navigation('/payment-confirm/' + randNum)
            })
        //  this.props.navigation('/payment-confirm')
    }
    componentDidMount() {
        console.log(this.state.paymentData)
        if (this.props.params.keyid) {
            const keyid = this.props.params.keyid;
            fetch('https://redeembank.com/api/get_clim_data.php?key_id=' + keyid)
                .then((response) => response.json())
                .then((resData) => {
                    console.log(resData);
                    this.setState({ user_id: resData.result.id })
                    this.setState({ user_email: resData.result.indv_email })
                    this.setState({ user_name: resData.result.fullname })
                    if (resData.result.status == 1 || resData.result.status == '1') {
                        //alert('redirect ..')
                        this.props.navigation('/payment-success');
                    }
                    if (resData.selling_product) {
                        this.setState({ paymentData: resData.selling_product.payment_data })
                        this.setState({ transaction: resData.selling_product.transaction })

                    }
                    //payment_data transaction
                    // user_id:'',
                    //user_email:'',

                    this.setState({ clemData: resData.result })
                })
        }



    }
    render() {

        return (
            <div>

                <Navbar></Navbar>
                <section className="py-5 position-relative main-div">
                    <div id="particles-js"></div>
                    <div className="container py-5 mt-5 z-index-20">
                        <h1 className="text-center text-color">Claim </h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item">
                                    <a className="text-decoration-none d-flex align-items-center text-color" href="#">
                                        <i className="las la-home la-sm me-1"></i>Home</a>
                                </li>
                                <li className="breadcrumb-item active text-color" aria-current="page">Claim</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section>
                    {/* style="background-color: #eee;" */}
                    <section class="h-100 h-custom" >
                        <div class="container py-5 h-100">
                            <div class="row d-flex justify-content-center align-items-center h-100">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-body p-4">
                                            <div style={{ textAlign: 'center' }}>
                                                <img src="https://www.redcross.org/content/dam/redcross/imported-images/redcross-logo.png.img.png" />
                                                <h3
                                                    style={{ paddingTop: '20px', fontSize: '20px' }}
                                                >Welcome {this.state.user_name} </h3>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="card text-white rounded-3">
                                                        <div class="card-body">
                                                            <div className="row">
                                                                <div className="row">
                                                                    {/* json{JSON.stringify(this.state.multiplePayments) } */}
                                                                    <div className="col-sm-3">
                                                                        <label>
                                                                            <input type="radio" name="option_pay"
                                                                                value={this.state.single_payment}
                                                                                checked={this.state.single_payment === true}
                                                                                onChange={(e) => { this.setState({ single_payment: true }) }}
                                                                            /> Single payment
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <label>
                                                                            <input type="radio" name="option_pay"
                                                                                value={this.state.single_payment}
                                                                                onChange={(e) => { this.setState({ single_payment: false }) }}
                                                                                checked={this.state.single_payment === false}
                                                                            /> Split payment
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-5 bg-cnhan">

                                                                    {this.state.single_payment === true &&
                                                                        <SingleMenu selectedMethod={this.paymentMethod} />
                                                                    }
                                                                    {this.state.single_payment === false &&
                                                                        <MultiplePay payDatas={this.getPMethods} />
                                                                    }

                                                                </div>
                                                                <div className="col-sm-7">
                                                                    <form class="mt-4">

                                                                        {this.state.clemData.amount > 0 && 
                                                                            <>
                                                                            {this.state.selectedPayment != 'voucher' &&
                                                                                <div class="form-outline form-white mb-4">
                                                                                    <label class="form-label" >Claim Amount ($) </label>
                                                                                    <input type="text" class="form-control form-control-lg" disabled="true"
                                                                                        value={this.state.clemData.amount}
                                                                                        style={{ color: 'black' }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                                <div class="form-outline form-white mb-4">
                                                                                    <label class="form-label" >Purpose</label>
                                                                                    <input type="text" class="form-control form-control-lg" disabled="true"
                                                                                        value={this.state.clemData.purposr}
                                                                                        style={{ color: 'black' }}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {this.state.single_payment === false && this.state.multiplePayments.length >0 &&
                                                                        <>
                                                                        { this.state.multiplePayments.map((payment,i)=>(
                                                                            <>
                                                                            <div key={i}>
                                                                                {payment=='card' &&
                                                                                <>
                                                                                <h3>Push2Card</h3>
                                                                                <Card datas={this.getMulTipleData} /> 
                                                                                <div>
                                                                                    <label>$Amount</label>
                                                                                    <input type="text" className="form-control"
                                                                                    onChange={(e)=>{this.handalAmount(e)}}
                                                                                    value={this.state.amounts.card}
                                                                                    name="card"
                                                                                     />
                                                                                </div>
                                                                                <hr />
                                                                                </>
                                                                                }
                                                                                {payment=='wire' &&
                                                                                <><h3>Wire</h3>
                                                                                <Wire datas={this.getMulTipleData} /> 
                                                                                <div>
                                                                                    <label>$Amount</label>
                                                                                    <input type="text" className="form-control" 
                                                                                    onChange={(e)=>{this.handalAmount(e)}}
                                                                                    value={this.state.amounts.wire}
                                                                                    name="wire"
                                                                                    />
                                                                                </div> <hr /></>
                                                                                }
                                                                                {payment=='ach' &&
                                                                                <> <h3>ACH</h3>
                                                                                <Ach datas={this.getMulTipleData} /> 
                                                                                <div>
                                                                                    <label>$Amount</label>
                                                                                    <input type="text" className="form-control" 
                                                                                    onChange={(e)=>{this.handalAmount(e)}}
                                                                                    value={this.state.amounts.ach}
                                                                                    name="ach"
                                                                                    />
                                                                                </div> <hr />
                                                                                </>
                                                                                }
                                                                                {payment=='rtp' &&
                                                                                <>
                                                                                <h3>RTP</h3>
                                                                                <Rtp datas={this.getMulTipleData} /> 
                                                                                <div>
                                                                                    <label>$Amount</label>
                                                                                    <input type="text" className="form-control" 
                                                                                    onChange={(e)=>{this.handalAmount(e)}}
                                                                                    value={this.state.amounts.rtp}
                                                                                    name="rtp"
                                                                                    />
                                                                                </div> <hr /> </>
                                                                                }
                                                                                {payment=='voucher' &&
                                                                                <><h3>Voucher</h3>
                                                                                <Voucher datas={this.getMulTipleData} /> 
                                                                                <div>
                                                                                    <label>$Amount</label>
                                                                                    <input type="text" className="form-control" 
                                                                                    onChange={(e)=>{this.handalAmount(e)}}
                                                                                    value={this.state.amounts.voucher}
                                                                                    name="voucher"
                                                                                    />
                                                                                </div> <hr /></>
                                                                                }
                                                                                {payment=='precard' &&
                                                                                <><h3>Prepaid Card</h3>
                                                                                <PreCard datas={this.getMulTipleData} /> 
                                                                                <div>
                                                                                    <label>$Amount</label>
                                                                                    <input type="text" className="form-control" 
                                                                                    onChange={(e)=>{this.handalAmount(e)}}
                                                                                    value={this.state.amounts.precard}
                                                                                    name="precard"
                                                                                    />
                                                                                </div> <hr /></>
                                                                                }
                                                                                {payment=='paypal' &&
                                                                                <><h3>Paypal</h3>
                                                                                <Payple datas={this.getMulTipleData} /> 
                                                                                <div>
                                                                                    <label>$Amount</label>
                                                                                    <input type="text" className="form-control"
                                                                                     onChange={(e)=>{this.handalAmount(e)}}
                                                                                     value={this.state.amounts.paypal}
                                                                                     name="paypal"
                                                                                     />
                                                                                </div>
                                                                                <hr />
                                                                                </>
                                                                                }
                                                                                
                                                                            </div>
                                                                            </>
                                                                        ))
                                                                         }
                                                                        </>
                                                                        }
                                                                        {this.state.single_payment === true &&
                                                                            <>
                                                                                {this.state.savepass == true &&
                                                                                    <>
                                                                                        <div class="form-outline form-white mb-4">
                                                                                            <label class="form-label" >Password (Optional) </label>
                                                                                            <input type="text" class="form-control form-control-lg"
                                                                                                value={this.state.password1}
                                                                                                onChange={(e) => { this.setState({ password1: e.target.value }) }}
                                                                                            />
                                                                                        </div>
                                                                                        <div class="form-outline form-white mb-4">
                                                                                            <label class="form-label" >Confirm Password (Optional) </label>
                                                                                            <input type="text" class="form-control form-control-lg"
                                                                                                value={this.state.password2}
                                                                                                onChange={(e) => { this.setState({ password2: e.target.value }) }}
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                                {this.state.selectedPayment == 'rtp' && this.state.savepass == false &&
                                                                                    <>
                                                                                        <Rtp datas={this.getCardData} />
                                                                                    </>
                                                                                }
                                                                                {this.state.selectedPayment == 'paypal' && this.state.savepass == false &&
                                                                                    <>
                                                                                        <Payple datas={this.getCardData} />
                                                                                    </>
                                                                                }
                                                                                {this.state.selectedPayment == 'card' && this.state.savepass == false &&
                                                                                    <>
                                                                                        <h3>Push2Card</h3>
                                                                                        <Card datas={this.getCardData} />
                                                                                    </>
                                                                                }
                                                                                {this.state.selectedPayment == 'voucher' && this.state.savepass == false &&
                                                                                    <>
                                                                                        <Voucher datas={this.getCardData} />
                                                                                    </>
                                                                                }
                                                                                {this.state.selectedPayment == 'precard' && this.state.savepass == false &&
                                                                                    <>
                                                                                        <PreCard datas={this.getCardData} />
                                                                                    </>
                                                                                }
                                                                                {this.state.selectedPayment == 'crypto' && this.state.savepass == false &&
                                                                                    <>
                                                                                        <Crypto datas={this.getCardData} />
                                                                                    </>
                                                                                }
                                                                                {this.state.selectedPayment == 'wire' && this.state.savepass == false &&
                                                                                    <>
                                                                                        <Wire datas={this.getCardData} />
                                                                                    </>
                                                                                }
                                                                                {this.state.selectedPayment == 'ach' && this.state.savepass == false &&
                                                                                    <>
                                                                                        <Ach datas={this.getCardData} />
                                                                                    </>
                                                                                }
                                                                                {this.state.selectedPayment == 'pickup' && this.state.savepass == false &&
                                                                                    <>
                                                                                        <Pickup datas={this.getCardData} />
                                                                                    </>
                                                                                }

                                                                            </>
                                                                        }

                                                                        <button type="button"
                                                                            class="btn btn-info btn-block btn-lg"
                                                                            onClick={this.goConfirmPament}
                                                                        >
                                                                            <div class="d-flex justify-content-between">
                                                                                <span>Send <i class="fas fa-long-arrow-alt-right ms-2"></i>
                                                                                    <FaGift />
                                                                                </span>
                                                                            </div>
                                                                        </button>



                                                                    </form>

                                                                </div>
                                                            </div>
                                                            <hr class="my-4" />


                                                        </div>
                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <Footer />

            </div>
        )
    }
}

export default withNavigateHook(PaymentSuccess)