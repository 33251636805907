import React, { Component } from "react";
import './style.css';
import Sidebar from "./Sidebar";
import Header from "./Header";

import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from 'react-router-dom';


class Dashboard extends Component{
    constructor(props){
        super(props)
        this.state = {};
    }
    render(){
        return(
            <div >
                <div class="wrapper">
                    <Sidebar />
                    <div id="content">
                        <Header />

                        <h2>Welcome</h2>
                        
                    </div>
                </div>
            </div>
        );
    }
}
export default Dashboard;