import React,{ useState } from 'react'
import { useEffect } from 'react';
import amozen from '../amozen.jpg';
import ebay from '../ebay.png';

function Voucher(props) {
    const [paymentData, setpaymentData] = useState({
        amount:'',
        product_id:''
    });
    const setVoucher = (e) =>{
       setpaymentData({...paymentData, voucher_type:e})
        console.log(paymentData)
    }
    const ChangeInput = (e)=>{
        const name = e.target.name; 
        const value = e.target.value; 
        setpaymentData({...paymentData,[name]:value})
    }
    const handalInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name)
        console.log(value)
        if (value != '') {
            if(value ==45038 || value =='45038'){
                setpaymentData({ ...paymentData, amount: 100,[name]: value })
            }else{
                setpaymentData({ ...paymentData, amount: 50,[name]: value })
            }
        }
        
    }

    useEffect(()=>{ 
        props.datas(paymentData)
        console.log(paymentData)
     },[paymentData])

    return (
        <div>
            <div className='row'>
                <div className='col-sm-6'>
                <div class="form-outline form-white mb-4">
                    <label>
                        <p>Ebay giftcard (US 100)</p>
                         <img src={ebay} style={{ height: '70px' }} />
                        <input type='radio' name='product_id' value="45038" onChange={handalInput} />
                    </label>
                </div>
                </div>
                <div className='col-sm-6'>
                <div class="form-outline form-white mb-4">
                    <label> <p>Ebay giftcard (US 50)</p>
                        <img src={ebay} style={{ height: '70px' }} />
                        <input type='radio' name='product_id' value="29854" onChange={handalInput} />
                        
                    </label>
                </div>
                </div>
            </div>
            {/* <div class="form-outline form-white mb-4">
                <label class="form-label" >Location</label>
                <input type="text" class="form-control form-control-lg"
                    name="location"
                    onChange={ChangeInput}
                    value={paymentData.location}

                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Bank details</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="bank_details"
                    onChange={ChangeInput}
                    value={paymentData.bank_details}
                />
            </div> */}
        </div>
    )
}

export default Voucher