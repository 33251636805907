import React,{ useState, useEffect } from 'react'
import card from  '../card.png'
import cash from '../cash.png';
import axios from 'axios';

function Pickup(props) {
    const [ paymentdata, setPaymentData ] = useState({pickup_type:'', location:''})
    const[ locationList, setLocationList] = useState([]);
    const handalInput = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setPaymentData({...paymentdata, [name]:value})
    }
    useEffect(()=>{ props.datas(paymentdata) },[paymentdata]);
    useEffect(()=>{
        // https://redeembank.com/api/location-list.php
        fetch("https://redeembank.com/api/location-list.php")
        .then((res)=>res.json())
        .then((json)=>{
           setLocationList(json)
        })
    })
    

  return (
    <div>
        <div className='row'>
                <div className='col-sm-6'>
                <div class="form-outline form-white mb-4">
                    <label> <img src={cash} style={{ height: '70px',background:'white',padding:'2px' }} />
                        <input type='radio' name='pickup_type' value="cash" onChange={handalInput} />
                    </label>
                </div>
                </div>
                <div className='col-sm-6'>
                <div class="form-outline form-white mb-4">
                    <label> <img src={card} style={{ height: '70px',background:'white',padding:'2px' }} />
                        <input type='radio' name='pickup_type' value="card" onChange={handalInput} />
                    </label>
                </div>
                </div>
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Location</label>
                <select name='location' value={paymentdata.location}
                onChange={handalInput}
                 className='form-control form-control-lg'>
                    <option value=''>Select option</option>
                    { locationList.length>0 && 
                    <>
                    { locationList.map((loc, i)=>(
                    <option key={i} value={loc.post_data.location}>
                        {loc.post_data.location}
                    </option>))}
                    </>
                    }
                </select>
            </div>
    </div>
  )
}

export default Pickup