import React, { Component } from "react";
import './Home.css'
import Navbar from "./Navbar";
import logo from './bank_logo.png';
import Galary from "./Galary";
import Footer from "./Footer";
import { FaFilter, FaLongArrowAltRight } from 'react-icons/fa';
import UserAuto from "./UserAuto";
import { useSearchParams } from "react-router-dom";
import withNavigateHook from "./withNavigateHook";
import QRCode from "react-qr-code";

class ProductDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: {},
            productFilter: false,
            filterAuthor: '',
            filterSort: '',
            filterCatgory: '',
            categorys: []
        }
        this.handalFilter = this.handalFilter.bind(this)
        this.getUser = this.getUser.bind(this)
        this.goPament = this.goPament.bind(this)
        this.logKey = this.logKey.bind(this)
    }
    logKey(){
        this.props.navigation('/login-key')
    }
    goPament(){
        var logData =JSON.parse( localStorage.getItem('user'))
        console.log(logData)// .email
        let res =  fetch("https://redeembank.com/api/send-email.php",{
            method:'POST',
            body: JSON.stringify({
                fullname: logData.fullname,
                email: logData.email,
                phone: logData.phone,
            })
        })
        .then((response) => response.json())
        .then((responseData) => {
            this.props.navigation('/payment-qr')
        })

        
    }
    getUser(id) {
        if (id) {
            this.setState({ filterAuthor: id })
        }
        console.log(id);
    }
    handalFilter(e) {
        e.preventDefault();
        this.setState({ productFilter: true })
    }
    componentDidMount() {
        fetch("https://redeembank.com/api/unit-list.php")
            .then((response) => response.json())
            .then((categorys) => {
                this.setState({ categorys: categorys.result })
            })
        fetch("https://redeembank.com/api/get-product.php?id=" + this.props.params.productid)
            .then((response) => response.json())
            .then((product) => {
                console.log(product)
                this.setState({ products: product.result[0] })
            })

        console.log(this.props.params.productid)
    }

    render() {

        return (
            <div>

                <Navbar></Navbar>
                <section className="py-5 position-relative main-div">
                    <div id="particles-js"></div>
                    <div className="container py-5 mt-5 z-index-20">
                        <h1 className="text-center text-color">Product Details</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item">
                                    <a className="text-decoration-none d-flex align-items-center text-color" href="#">
                                        <i className="las la-home la-sm me-1"></i>Home</a>
                                </li>
                                <li className="breadcrumb-item active text-color" aria-current="page">Product Details</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section>
                    {/* style="background-color: #eee;" */}
                    <section class="h-100 h-custom" >
                        <div class="container py-5 h-100">
                            <div class="row d-flex justify-content-center align-items-center h-100">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-body p-4">

                                            <div class="row">

                                                <div class="col-lg-9">
                                                    <h5 class="">
                                                        <a href="#!" class="text-body"><i
                                                        class="fas fa-long-arrow-alt-left me-2"></i>
                                                        Continue shopping</a>
                                                    </h5>
                                                    <hr />


                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between">
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        {/* {! this.state.products.img_url &&
                                                                        <img
                                                                        src={logo}
                                                                        class="img-fluid rounded-3" alt="Shopping item"
                                                                    /> }
                                                                        {this.state.products.img_url &&
                                                                        <img
                                                                            src={this.state.products.img_url}
                                                                            class="img-fluid rounded-3" alt="Shopping item"
                                                                        /> }  */}
                                                                        <QRCode value="test" />

                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        {this.state.products.name && 
                                                                        <h5>{this.state.products.name} </h5>}

                                                                        {this.state.products.details &&
                                                                        <p>Description: {this.state.products.details} </p>}
                                                                        {this.state.products.purposr &&
                                                                        <p>Purpose: {this.state.products.purposr} </p>}

                                                            <p>Author: {this.state.products.fullname} </p>

                                                            {this.state.products.indv_email &&
                                                            <p>Email: {this.state.products.indv_email} </p>}
                                                            {this.state.products.category_name &&
                                                            <p>Category: {this.state.products.category_name} </p>}
                                                           
                                                            {this.state.products.status == 0 && 
                                                            <button type="button" class="btn btn-info btn-block btn-lg"
                                                            onClick={this.logKey}
                                                            >
                                                                <div class="d-flex justify-content-between">
                                                                    <span></span>
                                                                    <span>Claim (${this.state.products.amount})
                                                                    <FaLongArrowAltRight />
                                                                    </span>
                                                                </div>
                                                            </button>}
                                                            {this.state.products.status == 1 && 
                                                            <button type="button" class="btn btn-info btn-block btn-lg"
                                                            >
                                                                <div class="d-flex justify-content-between">
                                                                    <span></span>
                                                                    <span>Already claimed 
                                                                    </span>
                                                                </div>
                                                            </button>}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>



                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <Footer />

            </div>
        )
    }
}
export default withNavigateHook(ProductDetails);