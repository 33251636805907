import React, { Component } from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Sidebar from "./Sidebar";
import Header from "./Header";


class Category extends Component{
    constructor(props){
        super(props);
        
        this.state ={
            id:'',
            name:'',
            details:'',
            unitList:[],
            edit:false
        }
        this.handalSubmit = this.handalSubmit.bind(this)
        this.deleteItm = this.deleteItm.bind(this)
        this.editItm = this.editItm.bind(this)
        this.handalUpdate = this.handalUpdate.bind(this)
    }
    editItm = async(itm)=>{
        this.setState({
            name: itm.name,
            details: itm.details,
            edit: true,
            id: itm.id
        })
    }
    deleteItm =async(e)=>{
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    let res = fetch("https://redeembank.com/api/delete-unit.php", {
                    method: "POST",
                    body: JSON.stringify({
                     id: e,
                    }),
                });
                setTimeout(() => {
                    fetch("https://redeembank.com/api/unit-list.php")
        .then((res)=>res.json())
        .then((json)=>{
            this.setState({unitList: json.result})
        })
                }, 1500);
        
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
        
    }
    handalUpdate = async(e)=>{
        let res = await fetch("https://redeembank.com/api/update-unit.php", {
            method: "POST",
            body: JSON.stringify({
                name: this.state.name,
                details: this.state.details,
                id: this.state.id,
            }),
        });
        if(res.status==200){
            this.setState( {name:'',details:'',edit:false, id:''})
            fetch("https://redeembank.com/api/unit-list.php")
        .then((res)=>res.json())
        .then((json)=>{
            this.setState({unitList: json.result})
        })
        }
        
    }
    handalSubmit = async(e)=>{
        let res = await fetch("https://redeembank.com/api/add-unit.php", {
            method: "POST",
            body: JSON.stringify({
                name: this.state.name,
                details: this.state.details,
            }),
        });
        if(res.status==200){
            this.setState( {name:'',details:''})
            fetch("https://redeembank.com/api/unit-list.php")
        .then((res)=>res.json())
        .then((json)=>{
            this.setState({unitList: json.result})
        })
        }
        
    }
    componentDidMount(){
        fetch("https://redeembank.com/api/unit-list.php")
        .then((res)=>res.json())
        .then((json)=>{
            this.setState({unitList: json.result})
        })
    }
    render(){
        return(
            <div >
                <div class="wrapper">
                    <Sidebar />
                    <div id="content">
                        <Header />
                        <h2>Category</h2>
                        <div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div class="card" >
                                        <div className="card-title p-3 mb-2 bg-secondary text-white">
                                            <h5 className="text-center">Add Category</h5>
                                        </div>
                                        <div class="card-body">
                                            <form >
                                                <div class="form-group">
                                                    <label>Category name:</label>
                                                    <input type="text" class="form-control"
                                                    value={this.state.name}
                                                    onChange={(e)=>{this.setState({name: e.target.value})}}
                                                     />
                                                </div>
                                                <div class="form-group">
                                                    <label>Description:</label>
                                                    <textarea className="form-control" 
                                                    value={this.state.details}
                                                    onChange={(e)=>{this.setState({details:e.target.value})}}
                                                    />
                                                </div>
                                                
                                                {this.state.edit==false &&
                                                <div class="d-grid gap-2 d-md-block p-3 ">
                                                    <button class="btn btn-primary btn-block" type="button"
                                                    onClick={this.handalSubmit}
                                                    >Add</button>
                                                </div>}
                                                {this.state.edit==true &&
                                                <div class="d-grid gap-2 d-md-block p-3 ">
                                                    <button class="btn btn-primary btn-block" type="button"
                                                    onClick={this.handalUpdate}
                                                    >Update</button>
                                                </div>}
                                                
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div class="card" >
                                        <div className="card-title p-3 mb-2 bg-secondary text-white">
                                            <h5 className="text-center">Category list</h5>
                                        </div>
                                        <div class="card-body">
                                            <table class="table table-dark table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Details</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.unitList.length>0 &&
                                                    <>
                                                {this.state.unitList.map((list, i)=>(
                                                    <tr key={list.id}>
                                                        <td>{(i+1)} </td>
                                                        <td>{list.name} </td>
                                                        <td>{list.details} </td>
                                                        <td>
                                                            <button className="btn btn-danger"
                                                             onClick={()=>this.deleteItm(list.id)}>Del</button>
                                                            <button className="btn btn-warning"
                                                             onClick={()=>this.editItm(list)}
                                                            >Edit</button>
                                                        </td>
                                                    </tr>
                                                    )) }
                                                    </>
                                                }
                                                </tbody>
                                            </table>                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Category;