import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";

class SellingList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productList:[]
        }
    }
    componentDidMount(){
        console.log(this.props)
        fetch("https://redeembank.com/api/selling_rec.php")
        .then((res)=>res.json())
        .then((json)=>{
            this.setState({productList: json.result})
        })
    }
    render() {
        return (
            <div >
                <div class="wrapper">
                    <Sidebar />
                    <div id="content">
                        <Header />
                        <p> List</p>
                        <div className="row">
                            <div className="col-sm-10">
                                <div class="card" >
                                    <div className="card-title p-3 mb-2 bg-secondary text-white">
                                        <h5 className="text-center">list</h5>
                                    </div>
                                    <div class="card-body">
                                        <table class="table table-dark table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Product Name</th>
                                                    <th>Price</th>
                                                    <th>User name</th>
                                                    <th>User phone</th>
                                                    <th>Address</th>
                                                    <th>Business</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.productList.length >0 &&
                                            <>
                                            {this.state.productList.map((list,i)=>(
                                                <tr key={list.id}>
                                                    <td>{(i+1)} </td>
                                                    <td>{list.name} </td>
                                                    <td>{list.amount} </td>
                                                    <td>{list.fullname} </td>
                                                    <td>{list.phone} </td>
                                                    <td>{list.address} </td>
                                                    <td>{list.business_name} </td>
                                                    <td>{list.date} </td>
                                                </tr>
                                            )) }
                                            </>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SellingList;