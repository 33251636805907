import React,{ useState } from 'react'
import { useEffect } from 'react';

function Rtp(props) {
    const [paymentData, setpaymentData] = useState({
        routing_code:'',
        name:'',
        crd_ac_num:''
    })
    const ChangeInput =(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        setpaymentData({...paymentData,[name]:value});
    }
    useEffect(()=>{ props.datas(paymentData) },[paymentData])
    return (
        <div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Routing Number</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    value={paymentData.routing_code}
                    onChange={ChangeInput}
                    name="routing_code"
                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Creditor AC/ Number: </label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="crd_ac_num"
                    onChange={ChangeInput}
                    value={paymentData.crd_ac_num}
                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Name: </label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="name"
                    onChange={ChangeInput}
                    value={paymentData.name}
                />
            </div>
        </div>
    )
}

export default Rtp