import React,{ Component } from "react";

import sm_logo from "./sm_logo.png"
class FooterTwo extends Component{
    constructor(props){
        super(props)
        this.state = {}
    }
    render(){
        return(
            <footer class="footer bg-map bg-dark">
                

                <div class="container">
                    <div class="pt-1 bg-body rounded-pill"></div>
                </div>

                <div class="container py-4">
                    <div class="row text-center">
                        <p class="text-muted text-sm mb-0">© 2023 All rights reserved SmartCard marketing Systems INC <span class="text-primary">
                        <a href="https://google.com" target="_blank" rel="noreferrer">
                            <img src={sm_logo} style={{height:'42px'}} />
                            </a>
                            </span></p>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterTwo