import React,{ useState, useEffect } from 'react'

function SingleMenu(props) {
    const [selectedMent, setselectedMent] = useState("card");

    const clickMenu = (e) =>{
        setselectedMent(e)
       // props.selectedMethod(e);
    }
    useEffect(()=>{
        props.selectedMethod(selectedMent);
    },[selectedMent])

    return (
        <div>
            <ul className="sid-menu">
            <li><a className={selectedMent=='card'?'active':''} onClick={(e)=>{clickMenu('card')}}>
                Push2Card</a>
                </li>
                <li><a className={selectedMent=='wire'?'active':''} onClick={(e)=>{clickMenu('wire')}}
                >Wire</a> </li>
                <li><a className={selectedMent=='ach'?'active':''} onClick={(e)=>{clickMenu('ach')}}
                >ACH</a> </li>
                <li><a className={selectedMent=='rtp'?'active':''} onClick={(e)=>{clickMenu('rtp')}}
                >RTP</a> </li>
                <li><a className={selectedMent=='voucher'?'active':''} onClick={(e)=>{clickMenu('voucher')}}
                >Voucher</a> </li>
                <li><a className={selectedMent=='precard'?'active':''} onClick={(e)=>{clickMenu('precard')}}
                >Prepaid Card</a> </li>
                <li><a className={selectedMent=='paypal'?'active':''} onClick={(e)=>{clickMenu('paypal')}}
                >Paypal </a> </li>
                <li><a className={selectedMent=='pickup'?'active':''} onClick={(e)=>{clickMenu('pickup')}}
                >Pickup</a> </li>

                <li><a
                >Save Password </a> </li>


            </ul>
        </div>
    )
}

export default SingleMenu