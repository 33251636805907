import React,{ Component } from "react";
import QRCode from "react-qr-code";
import Sidebar from "./Sidebar";
import Header from "./Header";

import withNavigateHook from "../withNavigateHook";

import ToggleButton from 'react-toggle-button'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import { FaCheckDouble, FaKey, FaKeybase } from "react-icons/fa";

class ProductList extends Component{
    constructor(props){
        super(props)
        this.state = {
            productList:[]
        }
        this.handelToggle = this.handelToggle.bind(this);
        this.handalClickEdit = this.handalClickEdit.bind(this)
        this.deleteItm = this.deleteItm.bind(this)
        this.addKey = this.addKey.bind(this)
        this.randomStr = this.randomStr.bind(this);
    }
    randomStr(len, arr) {
        let ans = '';
        for (let i = len; i > 0; i--) {
            ans +=
                arr[(Math.floor(Math.random() * arr.length))];
        }
        return ans;
    }
    addKey(pId){
        let update = fetch('https://redeembank.com/api/product-update.php',{
            method:'POST',
            body: JSON.stringify({
                prod_id: pId,
                addkey: this.randomStr(20, '12345abcde')
            })
        })
        .then((res)=>res.json())
        .then((res)=>{
        alert('Please reload!!')
        })
    }
    handelToggle(prod,v){
        prod.enabled = !v;
        this.state.productList.find((usr)=>{ 
            if(usr.id == prod.id){
                usr.enabled = !v;
                return;
            }})
        this.setState({productList: this.state.productList })
        let update = fetch('https://redeembank.com/api/product-update.php',{
            method:'POST',
            body: JSON.stringify({
                prod_id: prod.id,
                enabled: prod.enabled
            })
        })
    }
    deleteItm = (id) =>{
        fetch('https://redeembank.com/api/product-delete.php',
         {
            method: 'POST',
            body: JSON.stringify({
                id: id
            })
        })
        fetch("https://redeembank.com/api/product-list_1.php")
        .then((res)=>res.json())
        .then((json)=>{
            this.setState({productList: json.result})
        })
    }
    handalClickEdit = (data) =>{
        console.log(data);
        this.props.navigation('/add-product', { state: { id: 7, color: 'green' } })
    }
    componentDidMount(){
        console.log(this.props)
        fetch("https://redeembank.com/api/product-list_1.php")
        .then((res)=>res.json())
        .then((json)=>{
            this.setState({productList: json.result})
        })
    }
    render(){
        return(
            <div >
                <div class="wrapper">
                    <Sidebar />
                    <div id="content">
                        <Header />
                        <p>Products List</p>
                        <div className="row">
                        <div className="col-sm-10">
                                    <div class="card" >
                                        <div className="card-title p-3 mb-2 bg-secondary text-white">
                                            <h5 className="text-center">Product list</h5>
                                        </div>
                                        <div class="card-body">
                                        <table class="table table-dark table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Category</th>
                                                        <th>Description</th>
                                                        <th>Amount</th>
                                                        <th>QR code</th>
                                                        <th>Action</th>
                                                        <th>Enabled</th>
                                                        <th>Add key</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.productList.length>0 &&
                                                    <>
                                                {this.state.productList.map((list,i)=>(
                                                    <tr key={list.id}>
                                                        <td>{(i+1)} </td>
                                                        <td>{list.name} </td>
                                                        <td>{list.category} </td>
                                                        <td>{list.details.substring(0, 20)}... </td>
                                                        <td>{list.amount} </td>
                                                        <td>
                                                            {/* <a href={list.img_url} download>Download</a> */}
                                                            <QRCode
                                                            size={86}
                                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                            value={list.img_url+'|'+list.name+'|'+list.category+'|'+list.details+'|'+list.amount}
                                                            viewBox={`0 0 256 256`}
                                                            />
                                                        </td>
                                                        <td>
                                                        
                                                            <button className="btn btn-danger btn-sm"
                                                             onClick={()=>this.deleteItm(list.id)}>Del</button>
                                                             <button type="button" 
                                                             onClick={()=>{this.handalClickEdit(list)}}
                                                              className="btn btn-warning btn-sm">Edit</button>
                                                            
                                                        </td>
                                                        <td>
                                                        <ToggleButton
                                                            value={ list.enabled}
                                                            onToggle={(value) =>this.handelToggle(list,value) }
                                                             />
                                                        </td>
                                                        <td>
                                                            {!list.key_data &&
                                                            <button type="button" 
                                                            className="btn btn-sm btn-primary"
                                                            onClick={()=>{this.addKey(list.id)}}
                                                            >
                                                                <FaKey />
                                                            </button>
                                                            }
                                                            {list.key_data &&
                                                            <button type="button" className="btn btn-sm btn-success">
                                                                <FaCheckDouble />
                                                            </button>}
                                                        </td>
                                                    </tr>
                                                    )) }
                                                    </>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
//export default ProductList;
export default withNavigateHook(ProductList)