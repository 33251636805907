import React, { Component } from "react";

import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

class Register extends Component{
    constructor(props){
        super(props);
        this.state = {
            fullname:'',
            email:'',
            phone:"",
            purpose:'',
            address:'',
            password1:'',
            password2:'',
            passwordError:'',
            ipAddress:"",
            country:'',
            fError:false

         }
         this.checkPass = this.checkPass.bind(this)
         this.submit = this.submit.bind(this)
    }

    checkPass(val){
        console.log(val)
        this.setState({password2: val})

        if(this.state.password1 !=''){
            console.log('password1 = '+this.state.password1)
            console.log('password2 = ' +this.state.password2)
            if(this.state.password1 == val ){
                this.setState({passwordError:''})
                this.setState({fError:false})
            }else{
                this.setState({passwordError:'Please enter same password !'})
                this.setState({fError:true})
            }
        }
    }
    submit = async(e)=>{
        if(this.state.fullname =='' || this.state.email=='' || this.state.phone=='' || this.state.password1==''){
            alert('All fields are mandatory');
            return;
        }

        let res = await fetch("https://redeembank.com/api/add-user.php",{
            method:'POST',
            body: JSON.stringify({
                fullname: this.state.fullname,
                email: this.state.email,
                phone: this.state.phone,
                purpose: this.state.purpose,
                address: this.state.address,
                password: this.state.password1,
                ipAddress: this.state.ipAddress,
                country: this.state.country
            })
        })
        .then((response) => response.json())
        .then((responseData) => {

            this.setState({fullname:''});
            this.setState({email:''});
            this.setState({phone:''});
            this.setState({purpose:''});
            this.setState({address:''});
            this.setState({password1:''});
            this.setState({password2:''});
        })
    }
    componentDidMount(){
        fetch("https://api.ipify.org?format=json")
        .then((res)=>res.json())
        .then((json)=>{
            console.log(json.ip)
            this.setState({ipAddress: json.ip})
        })
        fetch("https://geolocation-db.com/json/")
        .then((res)=>res.json())
        .then((json)=>{
            console.log(json.ip)
            this.setState({country: json.country_name})
        })
    }

    render(){
        return(
            <div>
                <Navbar />

                <section className="py-5 bg-dark position-relative">
            <div id="particles-js"></div>
            <div className="container py-5 mt-5 z-index-20">
                <h1 className="text-center">Registration</h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item">
                            <a className="text-decoration-none d-flex align-items-center" href="#"> <i className="las la-home la-sm me-1"></i>Home</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Registration</li>
                    </ol>
                </nav>
            </div>
        </section>



        <section className="py-5 position-relative">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                            
                        <div className="card">
                            <div className="card-body p-4 p-lg-5">
                            
                            <br />
                            <br />
                                <form className="register-form" action="#">
                                    <div className="row gy-3">
                                        <div className="col-lg-6">
                                            <label className="form-label" for="fullname">Full name</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-user-circle"></i></div>
                                                <input className="form-control shadow-0 border-start-0" 
                                                type="text" autocomplete="off" name="fullname" 
                                                value={this.state.fullname}
                                                onChange={(e)=>{this.setState({fullname: e.target.value})}}
                                                placeholder="Enter your Full name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="email"> Email address</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-envelope"></i></div>
                                                <input className="form-control shadow-0 border-start-0" type="email" autocomplete="off" 
                                                name="email" 
                                                value={this.state.email}
                                                onChange={(e)=>{this.setState({email: e.target.value})}}
                                                placeholder="Enter your email address" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="phone"> Phone number</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-phone"></i></div>
                                                <input className="form-control shadow-0 border-start-0" type="text" autocomplete="off" name="phone" 
                                                 placeholder="Enter your phone number"
                                                 value={this.state.phone}
                                                 onChange={(e)=>{this.setState({phone:e.target.value})}}
                                                  />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="username">Purpose</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-at"></i></div>
                                                <input className="form-control shadow-0 border-start-0" type="text" autocomplete="off" 
                                                 placeholder="Enter Purpose"
                                                 value={this.state.purpose}
                                                 onChange={(e)=>{this.setState({purpose: e.target.value})}}
                                                  />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <label className="form-label" for="username"> Address</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-at"></i></div>
                                                <input className="form-control shadow-0 border-start-0" type="text" autocomplete="off" 
                                                value={this.state.address}
                                                onChange={(e)=>{this.setState({address: e.target.value})}}
                                                 placeholder="Enter your address" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="password"> Password</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-key"></i></div>
                                                <input className="form-control shadow-0 border-start-0" type="password" autocomplete="off" 
                                                value={this.state.password1}
                                                onChange={(e)=>{this.setState({password1:e.target.value})}}
                                                placeholder="Enter your password" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="passwordAlt"> Retype password</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-key"></i></div>
                                                <input className="form-control shadow-0 border-start-0" type="password" autocomplete="off" 
                                                value={this.state.password2}
                                                onChange={(e)=>this.checkPass(e.target.value) }
                                                 placeholder="Retype your password" />
                                                 
                                            </div>
                                            <span className="text-danger">{this.state.passwordError } </span>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                        <ReCAPTCHA
                                            sitekey="6LfV2hApAAAAACBwmWFGyuyzUglgAnFOiKk9BmHs"
                                            onChange={this.onChange}
                                        />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <button className="btn btn-primary w-100" 
                                            type="button"
                                            disabled={this.state.fError}
                                            onClick={this.submit}
                                            ><i className="las la-user-circle me-2"></i>Create your account</button>
                                        </div>
                                        <div className="col-12 text-center">
                                            <p className="mb-0 fw-light text-muted">Already registered! <a className="text-decoration-none" href="login"> Sigin now</a></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
                <Footer />
            </div>
        )
    }
}

export default Register;