import React, { Component } from "react";
import './Home.css'
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FaFilter, FaGift, FaLongArrowAltRight } from 'react-icons/fa';
import withNavigateHook from "./withNavigateHook";
import QRCode from "react-qr-code";

class PaymentQr extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        this.goConfirmPament = this.goConfirmPament.bind(this)
    }
    goConfirmPament() {
        console.log(this.props)
        this.props.navigation('/payment-confirm')
    }
    componentDidMount() {

    }
    render() {

        return (
            <div>

                <Navbar></Navbar>
                <section className="py-5 position-relative main-div">
                    <div id="particles-js"></div>
                    <div className="container py-5 mt-5 z-index-20">
                        <h1 className="text-center text-color">Gift voucher</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item">
                                    <a className="text-decoration-none d-flex align-items-center text-color" href="#">
                                        <i className="las la-home la-sm me-1"></i>Home</a>
                                </li>
                                <li className="breadcrumb-item active text-color" aria-current="page">Gift voucher</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <section>
                    {/* style="background-color: #eee;" */}
                    <section class="h-100 h-custom" >
                        <div class="container py-5 h-100">
                            <div class="row d-flex justify-content-center align-items-center h-100">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-body p-4">
                                            <div style={{ textAlign: 'center' }}>
                                                <img src="https://axepay.com/images/Axepay.png" />
                                            </div>
                                            <div class="row">


                                                <div class="col-lg-12">

                                                    <div class="card text-white rounded-3">
                                                        <div class="card-body">
                                                            <div className="row">
                                                                <div className="col-sm-5">
                                                                <QRCode
                                                                size={200}
                                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                value="http://redeembank.com/payment-success"
                                                                viewBox={`0 0 256 256`}
                                                                />
                                                                <br />
                                                                
                                                                </div>
                                                                <div className="col-sm-7">
                                                                <h6>
                                                                QR click on the link sent to your registered mail id to claim the product after payment.
                                                                </h6>
                                                                </div>
                                                            </div>
                                                            <hr class="my-4" />

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <Footer />

            </div>
        )
    }
}

export default withNavigateHook(PaymentQr)