import React,{ useState } from 'react'
import { useEffect } from 'react';

function Ach(props) {
    const [paymentData, setpaymentData] = useState({
        
        ac_num:'',
        routing_num:'',
        rcv_ac_num:'',
        ac_type:'',
        idenf:'',
        sec_code:'',
        transiction_type:'',

    })
    const ChangeInput =(e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setpaymentData({...paymentData, [name]:value})
    }
    useEffect(()=>{props.datas(paymentData)},[paymentData])
    return (
        <div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" > Account Number
                <span className='text-danger'>*</span>
                </label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="ac_num"
                    onChange={ChangeInput}
                    value={paymentData.ac_num}
                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" > Routing Number(ACH Code) :
                <span className='text-danger'>*</span></label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="routing_num"
                    onChange={ChangeInput}
                    value={paymentData.routing_num}
                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Receiver Account Number</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="rcv_ac_num"
                    onChange={ChangeInput}
                    value={paymentData.rcv_ac_num}
                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Account Type 
                <span className='text-danger'>*</span>
                </label>
                <select className='form-control form-control-lg' name="ac_type"
                value={paymentData.ac_type}
                    onChange={ChangeInput}>
                    <option value=''>Select</option>
                    <option value='checking'>Checking</option>
                    <option value='Saving'>Saving</option>
                </select>

            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Identification: <span className='text-danger'>*</span></label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="idenf"
                    onChange={ChangeInput}
                    value={paymentData.idenf} />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Bank Name  <span className='text-danger'>*</span>
                </label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="sec_code"
                    onChange={ChangeInput}
                    value={paymentData.sec_code} />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Transaction Type:</label>
                <select className='form-control form-control-lg' name="transiction_type"
                    onChange={ChangeInput}
                    value={paymentData.transiction_type} >
                        <option value=''>Select option</option>
                        <option value='push'>Push</option>
                        <option value='pull'>Pull</option>
                    </select>
            </div>
        </div>
    )
}

export default Ach