import React,{ Component } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";

import ToggleButton from 'react-toggle-button'

class BusinessList extends Component{
    constructor(props){
        super(props)
        this.state ={
            businessList: [],
            val: true,
            config:{
            toggleTheme: 'DefaultSwitch',
            title: 'Title',
            leftText:'On',
            rightText:'Off',
          }
        }
    }
    handelToggle(user,v){
        user.enabled = !v;
        this.state.businessList.find((usr)=>{ 
            if(usr.id == user.id){
                usr.enabled = !v;
                return;
            }})
        this.setState({businessList: this.state.businessList })
        let update = fetch('https://redeembank.com/api/business-update.php',{
            method:'POST',
            body: JSON.stringify({
                user_id: user.id,
                enabled: user.enabled
            })
        })
    }

    componentDidMount(){
        fetch("https://redeembank.com/api/business-list.php")
        .then((res)=>res.json())
        .then((json)=>{
            this.setState({businessList: json.result})
        })
    }
    render(){
        return(
            <div >
                <div class="wrapper">
                    <Sidebar />
                    <div id="content">
                        <Header />
                        <div className="row">
                        <div className="col-sm-12">
                                    <div class="card" >
                                        <div className="card-title p-3 mb-2 bg-secondary text-white">
                                            <h5 className="text-center">Business list</h5>
                                        </div>
                                        <div class="card-body">
                                            <table class="table table-dark table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Business Name</th>
                                                        <th>Email</th>
                                                        <th>Contact</th>
                                                        <th>Address</th>
                                                        <th>Country</th>
                                                        <th>State</th>
                                                        <th>City</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                {/*  */}
                                                <tbody>
                                                    {this.state.businessList.length >0 &&
                                                    <>
                                                    {this.state.businessList.map((busin, i)=>(
                                                        <tr key={busin.id}>
                                                            <td>{(i+1)} </td>
                                                            <td>{busin.business_name} </td>
                                                            <td>{busin.email} </td>
                                                            <td>{busin.phone} </td>
                                                            <td>{busin.address} </td>
                                                            <td>{busin.country} </td>
                                                            <td>{busin.state} </td>
                                                            <td>{busin.city} </td>
                                                            <td> 
                                                            <ToggleButton
                                                            value={ busin.enabled || false }
                                                            onToggle={(value) =>this.handelToggle(busin,value) }
                                                             />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </>
                                                     }
                                                </tbody>
                                            </table>                                        
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BusinessList;