import React,{ Component }  from "react";

import './Navbar.css'
import logo from './bank_logo.png';
import Wallet from "./Wallet";

class Navbar extends Component{
    constructor(props){
        super(props)
        this.state ={
            user:""
        }
    }
    
    componentDidMount(){
        var logData =JSON.parse( localStorage.getItem('user'))
        if(logData ==null){

        }else{
            this.setState({user: logData})

        }
    }
    render(){
        return(
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="navbar">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img className="img-fluid" src={logo} alt="CR" width="240" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 flex-lg-row align-items-lg-center">
                            <li className="nav-item"><a className="nav-link active" aria-current="page" href="home">Home</a></li>
                            <li className="nav-item have-sub"><a className="nav-link" aria-current="page" href="./register">Registration</a>
                            
                            <ul className="submenu">
                                <li><a href="/register">User Registration</a> </li>
                                <li><a href="/business-register">Business Registration</a> </li>
                            </ul>
                            </li>
                            {this.state.user=='' &&
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page"
                                 href="./login">Login</a>
                            </li>}
                            {this.state.user !='' &&
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page"
                                 href="./dashboard">Dashboard 
                                 </a>
                            </li>}
                            {this.state.user !='' &&
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page"
                                 href="./dashboard"> 
                                 {this.state.user.fullname}
                                 </a>
                            </li>}
                            {/* <li className="nav-item ms-lg-2">
                                <a className="btn btn-gradient-primary btn-sm px-3 d-lg-flex align-items-center"
                                 href="wallet.html"> <i className="las la-wallet me-2 transform-none"></i>Connect wallet </a>
                            </li> */}
                            <li>
                                <Wallet />
                            </li>
                           
                        </ul>
                    </div>

                </div>
            </nav>
        )
    }
}
export default Navbar;