import React,{useState } from 'react'
import { useEffect } from 'react';

function Wire(props) {
    const [paymentData, setpaymentData]= useState({
        bank_details:'',
        bank_swift:'',
        bank_name:'',
        ac_num:'',
        business_code:'',
        rcv_routing_num:'',
        id_code:'',
        identifier:'',
    })
    const ChangeInput = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setpaymentData({...paymentData, [name]:value})
    }
    useEffect(()=>{ props.datas(paymentData) },[paymentData])
    return (
        <div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Bank Routing Code</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="bank_details"
                    onChange={ChangeInput}
                    value={paymentData.bank_details} />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Bank SWIFTBIC <span className='text-danger'>*</span> </label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="bank_swift"
                    onChange={ChangeInput}
                    value={paymentData.bank_swift}
                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Account Number <span className='text-danger'>*</span></label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="ac_num"
                    onChange={ChangeInput}
                    value={paymentData.ac_num}
                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Bank name <span className='text-danger'>*</span></label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="bank_name"
                    onChange={ChangeInput}
                    value={paymentData.bank_name}
                />
                {/* <select class="form-control form-control-lg"
                    name="bank_name"
                    onChange={ChangeInput}
                    value={paymentData.bank_name} >
                    <option value="">Select bank</option>
                    <option value='JPMorgan Chase'>JPMorgan Chase</option>
                    <option value='Bank of America'>Bank of America</option>
                    <option value='Citibank'>Citibank</option>
                    <option value='Wells Fargo Bank'>Wells Fargo Bank</option>
                    <option value='U.S. Bank'>U.S. Bank</option>
                    <option value='PNC Bank'>PNC Bank</option>
                    <option value='Truist Bank'>Truist Bank</option>
                    <option value='Goldman Sachs Bank'>Goldman Sachs Bank</option>
                    <option value='Capital One'>Capital One</option>
                    <option value='TD Bank'>TD Bank</option>
                </select> */}
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Account Type 
                <span className='text-danger'>*</span>
                </label>
                <select className='form-control form-control-lg' name="ac_type"
                value={paymentData.ac_type}
                    onChange={ChangeInput}>
                    <option value=''>Select</option>
                    <option value='checking'>Checking</option>
                    <option value='Saving'>Saving</option>
                </select>
                
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Receiver Routing Number :</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="rcv_routing_num"
                    onChange={ChangeInput}
                    value={paymentData.rcv_routing_num}
                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Id Code :</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="id_code"
                    onChange={ChangeInput}
                    value={paymentData.id_code}
                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Identifier :<span className='text-danger'>*</span>
                </label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="identifier"
                    onChange={ChangeInput}
                    value={paymentData.identifier}
                />
            </div>

        </div>
    )
}

export default Wire