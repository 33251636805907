import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';


const withNavigateHook = (Component) => {
    return (props) => {
        const navigation = useNavigate();
        const location = useLocation();
        const params = useParams();
        //console.log(location)
        //console.log(props)
        return <Component newStat= {location}
         navigation={navigation} {...props}  params ={ params } location={location}  />
    }
}

export default withNavigateHook;