import React, { Component,useState } from "react";
import './Home.css'
import Navbar from "./Navbar";
import Footer from "./Footer";


import { FaFilter, FaSearch, FaSign, FaSignInAlt } from 'react-icons/fa';
import logo from './Redeembank_final_01.png';

import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import FooterTwo from "./FooterTwo";

import {Routes, Route, useNavigate} from 'react-router-dom';

const HomeTwo = ()=>{
    const navigate = useNavigate();
    const [searchKey, setSearchKey] = useState('');
    // this.props.navigation(`/payment-success/${responseData.result[0].key_data}`);
    const handalClick = () =>{
       // navigate('/products',{state:{keyval: searchKey}})
        ///product-details/${list.id}
        fetch("https://redeembank.com/api/galary-list.php?key=" + searchKey)
        .then((res) => res.json())
        .then((json) => {
            //this.setState({ productList: json.result })
            navigate(`/product-details/${json.result[0].id}`)
        })
    }

    return(
        <div>
                <section>
                    <div className="home-div">
                        <div className="nav-menu">
                            <ul>
                                <li><Link to="/login">
                                    Login
                                    </Link></li>
                            </ul>
                        </div>

                       <img src={logo} style={{height: '250px'}} />
                       <div className="text-div">
                        <p>Unlock Your Redemption: The Power of Payment Freedom</p>
                       </div>
                       <div className="input-group bo">
                       <input class="form-control py-2 home-input"
                        type="text" placeholder="Search Key/Ticket Number"
                        onChange={(e)=>{setSearchKey(e.target.value)}}
                         />
                        
                        {/* <ReCAPTCHA 
                        sitekey="6LfV98YoAAAAAENYz6dE1yDmmYKZEl0bUbJukzAZ"
                        /> */}
                       </div>
                       <div className="robot-div">
                        <ReCAPTCHA 
                            sitekey="6LfV2hApAAAAACBwmWFGyuyzUglgAnFOiKk9BmHs"
                            />
                        </div>
                        <div className="robot-div">
                        <button type="button" className="btn btn-white"
                        onClick={handalClick}
                        >Search</button>
                        </div>

                    </div>

                </section>
                <FooterTwo />

            </div>
    )
}

export default HomeTwo
//export default withNavigateHook(HomeTwo)