import React, { useState } from 'react'
import { useEffect } from 'react';
import { FaCcMastercard, FaCcVisa, FaCreditCard } from 'react-icons/fa';
import cardImages from '../cards.jpg';
import masterCard from '../master.png';
import visaCard from '../visa.png';
import amex from '../amex.png';

function PreCard(props) {
    const [paymentData, setpaymentData] = useState({
        location: '',
        bank_details: '',
        cardTYpe: ''
    })
    const ChangeInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setpaymentData({ ...paymentData, [name]: value })
    }
    useEffect(() => { props.datas(paymentData) }, [paymentData])
    return (
        <div>
            <div className=''>
                <div class="form-outline form-white mb-4">
                    <label> <img src={masterCard} style={{height: '30px'}} />
                        <input type='radio' name='cardtype' value="master" 
                        
                        onChange={ChangeInput} />
                    </label>
                </div>
                <div class="form-outline form-white mb-4">
                    <label> <img src={visaCard} style={{height: '30px'}} />
                        <input type='radio' name='cardtype' value="visa" onChange={ChangeInput} />
                    </label>
                </div>
                <div class="form-outline form-white mb-4">
                    <label> <img src={amex} style={{height: '30px'}} />
                        <input type='radio' name='cardtype' value="amex" onChange={ChangeInput} />
                    </label>
                </div>
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Location</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="location"
                    onChange={ChangeInput}
                    value={paymentData.location}
                />
            </div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Bank details</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="bank_details"
                    onChange={ChangeInput}
                    value={paymentData.bank_details}
                />
            </div>
        </div>
    )
}

export default PreCard