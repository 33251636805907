import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { FaAd, FaHackerNews } from "react-icons/fa";
//import { useLocation } from "react-router-dom";


class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            name: '',
            details: '',
            category: '',
            imgUrl: '',
            amount: '',
            categoryL: [],
            userId: -1,
            btnDiseable: true
        }

        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handalSubmit = this.handalSubmit.bind(this);
    }

    handalSubmit = async (e) => {
        console.log(this.state)
        let btndsbl = true;
        if (this.state.amount != '' && this.state.name != '' && this.state.category != '') {
            btndsbl = false;
        }
        if (btndsbl == true) {
            alert('All field are mandatory !!')
            return
        }
        let res = await fetch("https://redeembank.com/api/add-product.php", {
            method: "POST",
            body: JSON.stringify({
                name: this.state.name,
                details: this.state.details,
                category: this.state.category,
                amount: this.state.amount,
                imgUrl: this.state.imgUrl,
                userId: this.state.userId
            }),
        });
        if (res.status == 200) {
            this.setState({ name: '', details: '', category: '', imgUrl: '', amount: '' })
        }
    }
    handleFileUpload(event) {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("imagefile", file);
        axios
            .post("https://redeembank.com/api/upload.php", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            })
            .then((response) => {
                // handle the response imgUrl
                if (response.status == 200) {
                    this.setState({ imgUrl: response.data.url });
                }
            })
            .catch((error) => {
                // handle errors
                console.log(error);
            });
    }
    componentDidMount() {
        fetch("https://redeembank.com/api/unit-list.php")
            .then((res) => res.json())
            .then((json) => {
                this.setState({ categoryL: json.result })
            })
        //console.log(this)
        let userData = JSON.parse(localStorage.getItem("user"))
        this.setState({ userId: userData.id })
        console.log(userData.id)
        console.log(this.state.userId)
    }
    render() {

        return (
            <div >
                <div class="wrapper">
                    <Sidebar />
                    <div id="content">
                        <Header />
                        <p>Product upload </p>
                        <div className="row">
                            <div className="col-sm-10">
                                <div class="card" >
                                    <div className="card-title p-3 mb-2 bg-secondary text-white">
                                        <h5 className="text-center">Product upload</h5>
                                    </div>
                                    <div class="card-body">
                                        <form >
                                            <div class="form-group">
                                                <label>Name:</label>
                                                <input type="text" class="form-control"
                                                    value={this.state.name}
                                                    onChange={(e) => { this.setState({ name: e.target.value }) }}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Description:</label>
                                                <textarea className="form-control"
                                                    value={this.state.details}
                                                    onChange={(e) => { this.setState({ details: e.target.value }) }}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Amount ($):</label>
                                                <input type="text" class="form-control"
                                                    value={this.state.amount}
                                                    onChange={(e) => { this.setState({ amount: e.target.value }) }}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>Category: </label>
                                                <select className="form-control"
                                                    onChange={(e) => { this.setState({ category: e.target.value }) }}>
                                                    <option value="">--Select--</option>
                                                    {this.state.categoryL.length > 0 &&
                                                        <>
                                                            {this.state.categoryL.map((cat) => (
                                                                <option key={cat.id} value={cat.id}>{cat.name}</option>
                                                            ))}
                                                        </>
                                                    }
                                                </select>
                                            </div>
                                            {/* <div class="form-group">
                                                <label>Image:</label>
                                                <input type="file" className="form-control"
                                                    onChange={this.handleFileUpload}
                                                />
                                            </div> */}
                                            <div class="form-group">
                                                <label>Image:</label>
                                                <br />
                                                <label htmlFor="filePicker"
                                                    style={{ background: "grey", padding: "10px 10px" }}>
                                                    choose file
                                                </label>
                                                <input id="filePicker" style={{ visibility: "hidden" }} type={"file"}
                                                    onChange={this.handleFileUpload} />
                                            </div>

                                            {this.state.edit == false &&
                                                <div class="d-grid gap-2 d-md-block p-3 ">
                                                    <button class="btn btn-primary 
                                                    btn-block" type="button"
                                                        onClick={this.handalSubmit}
                                                    >Add Product
                                                    </button>
                                                </div>}
                                            {this.state.edit == true &&
                                                <div class="d-grid gap-2 d-md-block p-3 ">
                                                    <button class="btn btn-primary btn-block" type="button"
                                                        onClick={this.handalUpdate}
                                                    >Update</button>
                                                </div>}

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Product;