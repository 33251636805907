import React,{ Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
//import Country from "./admin/Country";
import ReCAPTCHA from "react-google-recaptcha";

class BusinessReg extends Component{
    constructor(props){
        super(props);
        this.state = {
            businessname:'',
            categorys:[],
            category:'',
            email:'',
            phone:"",
            countrys:[],
            country:'',
            states:[],
            state:'',
            citys:[],
            city:'',
            address:'',
            password1:'',
            password2:'',
            passwordError:'',
            ipAddress:"",
            
            fError:false,
            

         }
         this.checkPass = this.checkPass.bind(this)
         this.submit = this.submit.bind(this)
         this.handalChangeCountry = this.handalChangeCountry.bind(this)
         this.getStates = this.getStates.bind(this)
         this.getCitys = this.getCitys.bind(this)
         this.handalChangeState = this.handalChangeState.bind(this)
         this.onChange = this.onChange.bind(this)
    }
    getCitys(){
        if(this.state.state !=''){
            fetch("https://redeembank.com/api/get-cytes.php?state_id="+this.state.state)
            .then((res)=>res.json())
            .then((json)=>{
                this.setState({citys: json.result})
            })
        }
    }
    getStates(){
        if(this.state.country !=''){
            fetch("https://redeembank.com/api/get-states.php?country_id="+this.state.country)
            .then((res)=>res.json())
            .then((json)=>{
                this.setState({states: json.result})
            })
        }
    }
    onChange(value) {
        console.log("Captcha value:", value);
      }
    handalChangeState(e){
        const state_id = e.target.value;
        this.setState({state: state_id});
        setTimeout(() => {
            this.getCitys();
        }, 200);
    }
    handalChangeCountry(e){
        const country_id = e.target.value;
        this.setState({country: country_id})
        setTimeout(() => {
            console.log(this.state.country);
            this.getStates();
        }, 300);
    }

    checkPass(val){
        console.log(val)
        this.setState({password2: val})

        if(this.state.password1 !=''){
            console.log('password1 = '+this.state.password1)
            console.log('password2 = ' +this.state.password2)
            if(this.state.password1 == val ){
                this.setState({passwordError:''})
                this.setState({fError:false})
            }else{
                this.setState({passwordError:'Please enter same password !'})
                this.setState({fError:true})
            }
        }
    }
    submit = async(e)=>{
    
        if(this.state.email=='' || this.state.phone=='' || this.state.password1==''){
            alert('All fields are mandatory');
            return;
        }
        // console.log(this.state);
        // return;

        let res = await fetch("https://redeembank.com/api/add-business.php",{
            method:'POST',
            body: JSON.stringify({
                businessname: this.state.businessname,
                address: this.state.address,
                category: this.state.category,
                city: this.state.city,
                country: this.state.country,
                email: this.state.email,
                ipAddress: this.state.ipAddress,
                password2: this.state.password2,
                phone: this.state.phone,
                state: this.state.state,
                type_nft: this.state.type_nft,                
            })
        })
        .then((response) => response.json())
        .then((responseData) => {

            this.setState({businessname:''});
            this.setState({city:''});
            this.setState({country:''});
            this.setState({state:''});
            this.setState({category:''});
            this.setState({email:''});
            this.setState({phone:''});
            this.setState({address:''});
            this.setState({password1:''});
            this.setState({password2:''});
        })
    }
    componentDidMount(){
        fetch("https://redeembank.com/api/unit-list.php")
        .then((res)=>res.json())
        .then((json)=>{
            this.setState({categorys: json.result})
        })
        fetch("https://redeembank.com/api/countries.php")
        .then((res)=>res.json())
        .then((json)=>{
            this.setState({countrys: json.result})
        })
        /*
        fetch("https://api.ipify.org?format=json")
        .then((res)=>res.json())
        .then((json)=>{
            console.log(json.ip)
            this.setState({ipAddress: json.ip})
        })
        fetch("https://geolocation-db.com/json/")
        .then((res)=>res.json())
        .then((json)=>{
            console.log(json.ip)
            this.setState({country: json.country_name})
        })*/
    }

    render(){
        return(
            <div>
                <Navbar />

                <section className="py-5 bg-dark position-relative">
            <div id="particles-js"></div>
            <div className="container py-5 mt-5 z-index-20">
                <h1 className="text-center">Registration</h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item">
                            <a className="text-decoration-none d-flex align-items-center" href="#"> <i className="las la-home la-sm me-1"></i>Home</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Registration</li>
                    </ol>
                </nav>
            </div>
        </section>



        <section className="py-5 position-relative">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                            
                        <div className="card">
                            <div className="card-body p-4 p-lg-5">
                            
                            <br />
                                <form className="register-form" action="#">
                                    <div className="row gy-3">
                                    <div className="col-lg-12">
                                            <label className="form-label" for="username">Business name</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-at"></i></div>
                                                <input className="form-control shadow-0 border-start-0"
                                                 type="text" autocomplete="off" 
                                                value={this.state.businessname}
                                                onChange={(e)=>{this.setState({businessname: e.target.value})}} 
                                                  />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="fullname">Category</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-user-circle"></i></div>
                                                <select className="form-control"
                                                value={this.state.category} 
                                                onChange={(e)=>{this.setState({category: e.target.value})}}>
                                                    <option>Select category</option>
                                                    {this.state.categorys.length>0 &&
                                                    <>
                                                    {this.state.categorys.map((cate)=>(
                                                        <option value={cate.id}>{cate.name}</option>
                                                    ))}
                                                    </>
                                                     }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="email"> Type of nft</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-envelope"></i></div>
                                                <select className="form-control" value={this.state.type_nft} 
                                                onChange={(e)=>{this.setState({type_nft: e.target.value})}}
                                                >
                                                    <option>Select</option>
                                                    <option value="1">type 1</option>
                                                    <option value="2">type 2</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="phone"> Phone number</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-phone"></i></div>
                                                <input className="form-control shadow-0 border-start-0" 
                                                type="text" autocomplete="off" 
                                                value={this.state.phone}
                                                onChange={(e)=>{this.setState({phone: e.target.value})}}
                                                  />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="username">Email</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-at"></i></div>
                                                <input className="form-control shadow-0 border-start-0" type="text" autocomplete="off" 
                                               value={this.state.email}
                                                onChange={(e)=>{this.setState({email: e.target.value})}}
                                                  />
                                            </div>
                                        </div>
                                         <div className="col-lg-6">
                                            <label className="form-label" for="username">Country</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-at"></i></div>
                                                <select className="form-control" value={this.state.country}
                                                onChange={this.handalChangeCountry}>
                                                    <option value="">Select country</option>
                                                   {this.state.countrys.length >0 &&
                                                   <>
                                                   { this.state.countrys.map((country)=>(
                                                    <option key={country.id} value={country.id}>{country.name} </option>
                                                   )) }
                                                   </>
                                                     }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="username">State</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-at"></i></div>
                                                <select className="form-control shadow-0 border-start-0" 
                                                value={this.state.state}
                                                onChange={this.handalChangeState}>
                                                    <option value="">Select state</option>
                                                    {this.state.states.length>0 && 
                                                    <>
                                                    {this.state.states.map((state)=>(
                                                        <option key={state.id} value={state.id}>{state.name}</option>
                                                    ))}
                                                    </>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <label className="form-label" for="username">City</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-at"></i></div>
                                                {/* <input className="form-control shadow-0 border-start-0" type="text" autocomplete="off" 
                                               value={this.state.city} onChange={(e)=>{this.setState({city: e.target.value})}}
                                                  /> */}
                                                  <select className="form-control shadow-0 border-start-0"
                                                  value={this.state.city}
                                                  onChange={(e)=>{this.setState({city: e.target.value})}}
                                                  >
                                                    <option value="">Select City</option>
                                                    {this.state.citys.length>0 &&
                                                    <>
                                                    {this.state.citys.map((city)=>(
                                                        <option key={city.id} value={city.id}>{city.name}</option>
                                                    )) }
                                                    </>
                                                     }
                                                  </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <label className="form-label" for="username"> Address</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-at"></i></div>
                                                <input className="form-control shadow-0 border-start-0" type="text" autocomplete="off" 
                                                value={this.state.address}
                                                onChange={(e)=>{this.setState({address: e.target.value})}}
                                                 placeholder="Enter your address" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <label className="form-label" for="username"> Description</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-at"></i></div>
                                                <textarea className="form-control shadow-0 border-start-0"
                                                 
                                                 ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="password"> Password</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-key"></i></div>
                                                <input className="form-control shadow-0 border-start-0" 
                                                type="password" autocomplete="off" value={this.state.password1}
                                                onChange={(e)=>{this.setState({password1: e.target.value})}}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label" for="passwordAlt"> Retype password</label>
                                            <div className="input-group">
                                                <div className="input-group-text border-end-0 pe-0"><i className="text-primary las la-key"></i></div>
                                                <input className="form-control shadow-0 border-start-0" type="password" autocomplete="off" 
                                                value={this.state.password2}
                                                onChange={(e)=>this.checkPass(e.target.value) }
                                                 placeholder="Retype your password" />
                                                 
                                            </div>
                                            <span className="text-danger">{this.state.passwordError } </span>
                                        </div>

                                        <div className="col-lg-6 mb-3">
                                        <ReCAPTCHA
                                            sitekey="6LfV2hApAAAAACBwmWFGyuyzUglgAnFOiKk9BmHs"
                                            onChange={this.onChange}
                                        />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <button className="btn btn-primary w-100" 
                                            type="button"
                                            disabled={this.state.fError}
                                            onClick={this.submit}
                                            ><i className="las la-user-circle me-2"></i>Create your account</button>
                                        </div>
                                        <div className="col-12 text-center">
                                            <p className="mb-0 fw-light text-muted">Already registered! <a className="text-decoration-none" href="login"> Sigin now</a></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
                <Footer />
            </div>
        )
    }
}
export default BusinessReg;