import React,{ useState } from 'react'
import { useEffect } from 'react';

function Payple(props) {
    const [paymentData, setpaymentData] = useState({
        email:''
    })
    const ChangeInput = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setpaymentData({...paymentData, [name]:value})
    }
    useEffect(()=>{ props.datas(paymentData) },[paymentData])
    return (
        <div>
            <div class="form-outline form-white mb-4">
                <label class="form-label" >Email</label>
                <input type="text" class="form-control form-control-lg" siez="17"
                    name="email"
                    value={paymentData.email}
                    onChange={ChangeInput}
                />
            </div>
        </div>
    )
}

export default Payple