
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,useNavigate 
} from 'react-router-dom';

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'



import Home from './Home'; 
import Login from './Login';
import Register from './Registrion';
import Dashboard from './admin/Dashboard';
import Pagenotfound from './Pagenotfound';
import Category from './admin/Category';
import Product from './admin/Products';
import Userlist from './admin/Userlist';
import ProductList from './admin/ProductList';
import AddVoucher from './admin/AddVoucher';
import VoucherList from './admin/VoucherList';
import ProductDetails from './ProductDetails';
import PaymentSuccess from './PaymentSuccess';
import BusinessReg from './BusinessReg';
import BusinessList from './admin/BusinessList';
import SellingList from './admin/SellingList';
import ConfirmPayment from './ConfirmPayment';
import PaymentQr from './PaymentQr';
import LoginKey from './LoginKey';
import HomeTwo from './HomeTwo';
import GalaeryList from './GalaeryList';



function App() {

  

  
  return (
    <>
    
    <Router>
      <Routes>
        <Route exact path="/"  element={< HomeTwo />}></Route>
        <Route exact path="/home"  element={< HomeTwo />}></Route>
        <Route exact path="/products"  element={< GalaeryList />}></Route>
        <Route exact path="/product-details/:productid"  element={< ProductDetails />}></Route>
        <Route  path="/login"  element={< Login />}></Route>
        <Route  path="/login-key"  element={< LoginKey />}></Route>
        <Route exact path="/register"  element={< Register />}></Route>
        <Route exact path="/business-register"  element={< BusinessReg />}></Route>
        <Route exact path="/dashboard"  element={< Dashboard />}></Route>
        <Route exact path="/category"  element={< Category />}></Route>
        <Route exact path='/add-product' element={<Product /> }></Route>
        <Route exact path='/product-list' element={<ProductList /> }></Route>
        <Route exact path='/selling-list' element={<SellingList /> }></Route>

        <Route path='/payment-success/:keyid' element={<PaymentSuccess /> }></Route>
        <Route exact path='/payment-qr' element={<PaymentQr /> }></Route>
        <Route exact path='/payment-confirm/:transaction_id' element={<ConfirmPayment /> }></Route>

        <Route exact path="/user-list" element={<Userlist /> }></Route>
        <Route exact path="/business-list" element={<BusinessList /> }></Route>
        <Route path='/add-voucher' element={<AddVoucher /> }></Route>
        <Route path='/voucher-list' element={<VoucherList /> }></Route>
        <Route path="*" element={< Pagenotfound />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
